import React, {useCallback} from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Tabs from '../Tabs';

export default function Partners({widget}) {
  /*
  fieldGroupName
  intro
  title*/

  //grab the awards from the optins page
  const partnerData = useStaticQuery(graphql`
    query {
	  wp {
	    acfOptionsOptions {
	      options {
	        widgetPartners {
	          networks {
				image {
		          localFile {
				    childImageSharp {
			           gatsbyImageData(
			             placeholder: BLURRED
						 formats: [PNG]
			           )
			         }
		          }
	              mediaDetails {
	                width
	                height
	              }
		        }
			    title
	            url
	          }
			  clubs {
				image {
		          localFile {
				    childImageSharp {
			           gatsbyImageData(
			             placeholder: BLURRED
						 formats: [PNG]
			           )
			         }
		          }
	              mediaDetails {
	                width
	                height
	              }
		        }
			    title
	            url
	          }
			  packagers {
				image {
		          localFile {
				    childImageSharp {
			           gatsbyImageData(
			             placeholder: BLURRED
						 formats: [PNG]
			           )
			         }
		          }
	              mediaDetails {
	                width
	                height
	              }
		        }
			    title
	            url
	          }
	        }
	      }
	    }
	  }
    }
  `)

  const getImageStyle = function(image) {
	let width=image?.mediaDetails?.width,
		height=image?.mediaDetails?.height;
	if (width && height) {
		return {width: Math.floor(width/2), height: Math.floor(height/2)};
	}
	return null;
  }

  const tabTitles = ['Networks', 'Clubs', 'Packagers'];

  const getTabContent = useCallback((index) => {	
	
	let tabData = false;
	
	switch(index) {
		case 1:
			tabData = partnerData?.wp?.acfOptionsOptions?.options?.widgetPartners?.clubs;
			break
		case 2:
			tabData = partnerData?.wp?.acfOptionsOptions?.options?.widgetPartners?.packagers;
			break
		default:
			tabData = partnerData?.wp?.acfOptionsOptions?.options?.widgetPartners?.networks;
			break
	}
	
	if (tabData) {
		return(
			<div className="mobile-scroll">
				<div className="mobile-scroll2">
				    {tabData.map((item, i) => (
					    <div className="partner" key={`partner${i}`}>
							{
								item.url
								? <a href={item.url} target="_blank" rel="noreferrer"><GatsbyImage image={getImage(item.image.localFile)} alt={item.title} style={getImageStyle(item.image)} /></a>
								: <GatsbyImage image={getImage(item.image.localFile)} alt={item.title} style={getImageStyle(item.image)} />
							}
					    </div>
				    ))}
				  </div>
			  </div>
		)
	}
  }, [partnerData]);

  return(
	<section className="widget partners internal white-bg">	
		<div className="top">
			<h2>{widget.title}</h2>
			<div className="intro" dangerouslySetInnerHTML={{ __html: widget.intro }} />
		</div>
		
		<Tabs label="Partner Tabs" tabTitles={tabTitles} getTabContent={getTabContent} />	
	</section>
  )
}

