import React, { useState, useEffect, useCallback } from "react";
import { Link } from "gatsby"
import { DateTime } from "luxon";

export default function Careers({widget}) {
	/*title
      careers {
		title
        uri
        date
        careers {
          vacancyLocation
        }
	  }
      */

  const [response, setResponse] = useState(false);
  const [gotError, setGotError] = useState(false);

  const handleResponse = useCallback(async (response) => {
	
	if (response.ok) {
        const content = await response.json();
	    setResponse(content);
	} else {
		setGotError(true);
	}
	
	//scrollToResults();
  }, [setResponse, setGotError]);

  const loadJobs = useCallback(async () => {
	
	let apiUrl = process.env.GATSBY_GREENHOUSE_API || "https://boards-api.greenhouse.io/v1/boards/landbay/jobs/";
	
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      //const content = await rawResponse.json();
      handleResponse(response);

    } catch (err) {
      console.log('caught error');
      console.log(err);
      handleResponse(false);
    }

  }, [handleResponse]);


  /*if we've just navigated from the product range widget we might want to select a product range*/
  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  return(
	  <section className="widget careers internal white-bg" ref={widget.ref}>
		<h2>{widget.title}</h2>			
		{
		  gotError
		  ? <div className="got-error">
			  <p>There was a technical error retrieving jobs</p>
			  <p>Please try again later</p>
			</div>
		  : response
			? response?.jobs?.length
			  ? <>
					{
					  widget.content
					  ? <div className="copy" dangerouslySetInnerHTML={{ __html: widget.content }} />
					  : null
					}
					<ul className="vacancies">
					  {response.jobs.map((job, i) => (					    
			    	    <li key={`job${i}`}>
						  <Link to="/vacancy/" state={{ job: job.id }}>
							{
							  job.updated_at
							  ? <p className="date">{DateTime.fromISO(job.updated_at).toLocaleString(DateTime.DATE_MED)}</p>
							  : null
							}
							
						  	<h3>{job.title}</h3>
					
							{
							  job.location?.name
							  ? <p className="location">{job.location.name}</p>
							  : null
							}
							
						  </Link>
						</li>
			          ))}
				    </ul>
				  </>
			  : <p>There are currently no open positions</p>				
			: <div className="loading"></div>			  
		}
	  </section>
  )
}

