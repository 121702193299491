import React from "react";
import InternalShapes from "./InternalShapes";

export default function HorizontalLine({page}) {
	
  return(
	<div className="internal horizontal-line">
		<InternalShapes page={page} widget="HorizontalLine" />
	</div>
  )
}

