import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import QuoteIcon from "../../assets/svg/inline/ic_quote.svg"

export default function Quote({widget}) {
	/*fieldGroupName
      layout
	  copy {
        text
        quoteSourceCompany
        quoteSourceName
	  }
	  images {
          image {
            localFile {
			  childImageSharp {
		        gatsbyImageData(
				  sizes: "(min-width: 768px) 1280px, 100vw",
				  breakpoints: [2560]
		        )
		      }
            }
			altText
          }
          mobileImage {
            localFile {
			  childImageSharp {
		        gatsbyImageData(
				  sizes: "88px",
				  breakpoints: [176]
		        )
		      }
            }
			altText
          }
      }*/



  const Quote50 = () => (
	<section className={`widget quote white-bg ${widget.layout}`}>	
		<div className="internal">
		    <div className="columns">
		    	<div className="image">
					<GatsbyImage image={getImage(widget.images.image.localFile)} alt={widget.images.image.altText} />
				</div>
				<div className="copy">
					<figure>
					    <blockquote>
					        <p dangerouslySetInnerHTML={{ __html: widget.copy.text }} />
					    </blockquote>
					    <figcaption>
							{widget.copy.quoteSourceName}
							{
								widget.copy.quoteSourceCompany
								? <cite>, {widget.copy.quoteSourceCompany}</cite>
								: null
							}
						</figcaption>
					</figure>
				</div>
			</div>		
		</div>
	</section>
  );

  const Quote100 = () => (
	<section className={`widget quote ${widget.layout}`}>	
		<div className="internal">
			<div className="copy">
				<figure>
				    <blockquote>
						<QuoteIcon />
				        <p dangerouslySetInnerHTML={{ __html: widget.copy.text }} />
				    </blockquote>
				    <figcaption>
						{widget.copy.quoteSourceName}
						{
							widget.copy.quoteSourceCompany
							? <cite>, {widget.copy.quoteSourceCompany}</cite>
							: null
						}
					</figcaption>
				</figure>
			</div>
			<GatsbyImage image={getImage(widget.images.image.localFile)} alt={widget.images.image.altText} className="desktop" />
			<GatsbyImage image={getImage(widget.images.mobileImage.localFile)} alt={widget.images.mobileImage.altText} className="mobile" />		
		</div>
	</section>
  );

  const QuoteGlassdoor = () => (
	<section className={`widget quote white-bg ${widget.layout}`}>	
		<div className="internal">
			<div className="copy">
				<figure>
				    <blockquote>
						<QuoteIcon />
				        <p dangerouslySetInnerHTML={{ __html: widget.copy.text }} />
				    </blockquote>
				    <figcaption>
						{widget.copy.quoteSourceName}
						{
							widget.copy.quoteSourceCompany
							? <cite>, {widget.copy.quoteSourceCompany}</cite>
							: null
						}
					</figcaption>
					<div className="glassdoor"><a href='https://www.glassdoor.co.uk/Overview/Working-at-Landbay-EI_IE2431186.11,18.htm'><img src='https://www.glassdoor.co.uk/api/widget/verticalStarRating.htm?e=2431186' alt="Glassdoor rating" /></a></div>
				</figure>
			</div>		
		</div>
	</section>
  );

    return (<>{
      {
        'two_columns': <Quote50 />,
        'full_width': <Quote100 />,
        'glassdoor': <QuoteGlassdoor />
      }[widget.layout]
    }</>)
}

