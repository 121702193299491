/** 
 * Separate off the trustpilot TrustBox so it can be reused in widgets/Triustpilot.js and widgets/AwardBar.js
 */
import React, {useRef, useEffect} from 'react';
import useScript from "../../hooks/useScript"

const TrustBox = (props) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);

  //load in the trust pilot script via our useScript hook
  useScript("https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js");

  useEffect(() => {
	// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
	// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
	// When it is, it will automatically load the TrustBox.
	if (window && window.Trustpilot && ref.current) {
	  window.Trustpilot.loadFromElement(ref.current, true);
	}
  }, []);

  if (props.attributes && props.attributes.hasOwnProperty('data-template-id')) {
	return (
	  <div ref={ref} className="trustpilot-widget" {...props.attributes}>
	    <a href="https://uk.trustpilot.com/review/landbay.co.uk" target="_blank" rel="noopener noreferrer">Trustpilot</a>
	  </div>
    );
  }

  if (props.templateId) {
	return (
	  <div ref={ref}
	    className="trustpilot-widget" data-locale="en-GB" data-template-id={props.templateId} data-businessunit-id="5c044d80e32b98000128fb9e" data-style-height={props.height} data-style-width="100%" data-theme="light">
	    <a href="https://uk.trustpilot.com/review/landbay.co.uk" target="_blank" rel="noopener noreferrer">Trustpilot</a>
	  </div>
    );
  }
	
  return null;
  
};

export default TrustBox;