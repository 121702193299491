import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";

export default function Sitemap() {	

  //grab the list of pages
  const pages = useStaticQuery(graphql`
    query {
	  allWpPage(sort: {fields: title}) {
	    nodes {
	      title
	      uri
		  parentId
	      wpChildren {
	        nodes {
	          ... on WpPage {
	            title
	            uri
	          }
	        }
	      }
	    }
	  }
    }
  `)

  return(
	<section className="internal widget sitemap white-bg">
	  <ul>
		{pages?.allWpPage?.nodes?.length && pages.allWpPage.nodes.map((page, i) => {
		  if (page.parentId===null) {
			return (
			  <li key={`page${i}`}>
			    <Link to={page.uri}>{page.title}</Link>
			    {
				  page.wpChildren?.nodes?.length
				  ? <ul>
			  	      {page.wpChildren.nodes.map((child, j) => (
					    <li key={`child${j}`}>
			  		      <Link to={child.uri}>{child.title}</Link>
					    </li>
			  	      ))}
				    </ul>
				  : null
			    }
			  </li>	
			)
		  }
		  return null;
		})}
	  </ul>
	</section>
  )
}

