import React from "react";
import { Link } from "gatsby";
import Arrow from "../../assets/svg/inline/ic_arrow.svg"

export default function QuickLinks({widget}) {
	/*fieldGroupName
      title
      links {
        link {
          url
          title
          target
        }
      }*/
    return(
		<section className="widget quicklinks internal white-bg">	
        	<h2>{widget.title}</h2>
			<div className="links">
				{widget.links && widget.links.map(({link}, i) => {
				  if (link.target && link.target==='_blank') {
					return (<a href={link.url} key={`link${i}`} target="_blank" rel="noreferrer"><Arrow /><span>{link.title}</span></a>);
				  }
				  return (
				    <Link to={link.url} key={`link${i}`}><Arrow /><span>{link.title}</span></Link>
				  );
				})}
			</div>
		</section>
    )
}

