import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import InternalShapes from "./InternalShapes";

export default function HowWeDoThings({widget, page}) {
	/*fieldGroupName
      layout
      title
      blocks {
        image {
          localFile {
		    childImageSharp {
	          gatsbyImageData(
			    sizes: "240px",
	            breakpoints: [480]
	          )
	        }
          }
        }
        title
        copy
      }*/

  return(
	<section className={`widget how-we-do-things ${widget.layout}`}>	
	  <InternalShapes page={page} widget="HowWeDoThings" />
		<div className="internal">
			<h2>{widget.title}</h2>
			
			<div className="blocks">
			  {widget?.blocks?.length && widget.blocks.map((block, i) => (
				  <div key={`block${i}`} className="block">
				    {
					  block.image
					  ? <GatsbyImage image={getImage(block.image.localFile)} alt={block.title} />
					  : null
				    }
					<h3>{block.title}</h3>
					<div className="copy" dangerouslySetInnerHTML={{ __html: block.copy }} />
				  </div>
			  ))}
			</div>
		</div>
	</section>
  )
}

