import React, {useRef, useEffect, Fragment} from "react";
import Button from '../Button';
import { useStaticQuery, graphql } from "gatsby"
import { getMaxLTV, getRate } from '../products/Product';
import { gsap } from "gsap";
import ScrollTrigger from "../../assets/plugins/ScrollTrigger";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger); 
}

export default function FeaturedProduct({widget}) {
	/*copy {
        newSticker
        title
        intro
        button {
          target
          title
          url
        }
      }
      product {
        backgroundColour
        source
        landbayProduct
        manual {
          line1
          line2
          line3
          line4
          line5
          line6
          line7
        }
      }*/

  //grab the products from the json feed
  const productData = useStaticQuery(graphql`
    query {
	  json: d9A424Db03Fd2Dff131117071192E722Json {
	    products {
		  productCode
	      productName
	      productType
	      rate
	      maxLtv
	      productRules {
	        ruleDescr
	      }
	    }
	  }
    }
  `)

    const trigger = useRef();
    const shape1 = useRef();
    const shape2 = useRef();

    useEffect(() => {
	    var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: trigger.current,
                        //markers: true,
                        start: "top center",
                        end: "bottom top",
                        invalidateOnRefresh: true,//https://greensock.com/st-mistakes/#update-refresh
                        scrub: true,
				    }
                  });
	    tl.to(shape1.current, {y: -150})
	      .to(shape2.current, {y: -120}, 0);
    });

    return(
		<section className="widget featured-product internal white-bg">	
        	<div className="columns">
				<div className="copy">
					{
						widget.copy.newSticker
						? <p className="new-sticker" style={{backgroundColor : widget.product.backgroundColour}}>{widget.copy.newSticker}</p>
						: null
					}
					<h2>{widget.copy.title}</h2>					
					<div className="intro" dangerouslySetInnerHTML={{ __html: widget.copy.intro }} />
					{
					  widget.copy.button
					  ? <Button link={widget.copy.button} className="solid purple" />				  
					  : null
					}	
				</div>
				<div className="product" ref={trigger}>
				  {
					widget.product.source==='manual'
					? <>
						<div className="box" style={{backgroundColor : widget.product.backgroundColour}}>
						  <p className="big">{widget.product.manual.line1}</p>
						  <hr />
						  <p className="small">{widget.product.manual.line2}</p>
						  <p className="big">{widget.product.manual.line3}</p>
						  <p className="small">{widget.product.manual.line4}</p>
						  <hr />
						  <p className="small">{widget.product.manual.line5}</p>
						  <p className="big">{widget.product.manual.line6}</p>
						  <p className="small">{widget.product.manual.line7}</p>							  
						</div>
						<div className="shape shape1" ref={shape1}></div>
						<div className="shape shape2" ref={shape2}></div>
					  </>
					: <>
					  {/*see if we can find the selected product in the product data*/}
					  {widget.product.landbayProduct && productData?.json?.products?.length && productData.json.products.map((product, i) => {
					    if (product.productCode===widget.product.landbayProduct) {
							return(
								<Fragment key={`product${i}`}>
									<div className="box" style={{backgroundColor : widget.product.backgroundColour}}>
										<p className="big">{product.productType}</p>
										{product.maxLtv && (
										  <>
										    <hr />
											<p className="small">Up to</p>
											<p className="big">{getMaxLTV(product)}</p>
											<p className="small">LTV</p>
										  </>
										)}
										{product.rate && (
										  <>
											<hr />
											<p className="small">From</p>
											<p className="big">{getRate(product)}</p>
											<p className="small">Rate</p>
										  </>
										)}
									</div>
									<div className="shape shape1" ref={shape1}></div>
									<div className="shape shape2" ref={shape2}></div>
								</Fragment>
							)
					    }	
						return null;
					  })}
					  </>
				  }
				  {
					widget.copy.button
					? <p className="buttonp"><Button link={widget.copy.button} className="solid purple" /></p>			  
					: null
				  }	
				</div>
			</div>
		</section>
    )
}

