import React, {useState} from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '../Button';
import Accordion from '../Accordion';
import PlayIcon from "../../assets/svg/inline/ic_play.svg"

export default function Video({widget}) {

	/*fieldGroupName
      layout
	  video {
	    image {
          localFile {
		    childImageSharp {
	           gatsbyImageData(
				 sizes: "(min-width: 1280px) 500px, (min-width: 768px) calc(50vw - 24px), 284px",
				 breakpoints: [568, 1000]
	           )
	         }
          }
        }
		url
		hash
      }
	  copy {
		subtitle
        title
        accordion {
          text
          title
	      image {
            localFile {
		      childImageSharp {
	            gatsbyImageData(
			      sizes: "(min-width: 1280px) 616px, (min-width: 768px) calc(50vw - 24px), 284px",
				  breakpoints: [568, 1232]
	            )
	          }
            }
          }
        }
        text
        button {
          target
          title
          url
        }
      }
      */
/*
    key: 'getVimeoUrl',
    value: function getVimeoUrl(vimeo, videoId) {
      var query = this.getQueryString(vimeo);
      return '//player.vimeo.com/video/' + videoId + '?' + query;
    }
https://player.vimeo.com/video/336257407?api=false&autopause=true&autoplay=true&byline=true&loop=false&portrait=true&title=true&xhtml=false

*/

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal     	      = () => setModalOpen(previousState => !previousState);

  const getWidgetClasses = function(layout) {
	if (layout==='grey') {
		return 'widget content video grey';
	}
	return 'widget content video white-bg '+layout;
  }

  const getButtonClasses = function(layout) {
	if (layout==='grey') {
		return 'solid black';
	}
	return 'solid purple';
  }

  const getVimeoId = function(url) {
	let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
	let match = url.match(regEx);
	if (match && match.length === 7) {
	    return match[6];
	}
	else {
	    //Handle error
		return null;
	}
  }

  const getEmbedUrl = function(video) {
	const videoId = getVimeoId(video.url);
	let url = "https://player.vimeo.com/video/"+videoId+"?autoplay=1&color=fecc37&title=0&byline=0&portrait=0";
	
	//do we have a hash parameter?
	if (video.hash) {
		url += "&h="+video.hash;
	}
	
	return url;
  }

  return(
	<section className={getWidgetClasses(widget.layout)}>
	  <div className="internal">
		<div className="image video">

		  <button className="plain trigger" onClick={toggleModal}>
		    <GatsbyImage image={getImage(widget.video.image.localFile)} alt={widget.copy.title} />
			<PlayIcon />
		  </button>

		{
		  modalOpen
		  ? <button className="plain modal" onClick={toggleModal}>
			  <div className="wrap">
				<iframe src={getEmbedUrl(widget.video)} title="Vimeo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
			  </div>
			</button>
		  : null
		}
		</div>
		<div className="copy">
		  {
			widget.copy.subtitle
			? <p className="subtitle">{widget.copy.subtitle}</p>
			: null
		  }
				
		  <h2>{widget.copy.title}</h2>
		
		  {
			widget.copy?.accordion?.length
			? <Accordion items={widget.copy.accordion} />
			: null
		  }
		
		  {widget.copy.text && (
		    <div className="text" dangerouslySetInnerHTML={{ __html: widget.copy.text }} />
		  )}
		
		  {
		    widget.copy.button
		    ? <Button link={widget.copy.button} className={getButtonClasses(widget.layout)} />				  
		    : null
		  }			
		</div>
	  </div>
	</section>
  )
}

