import React, {useRef, useEffect, useCallback} from "react";
import { gsap } from "gsap";
import ScrollTrigger from "../../assets/plugins/ScrollTrigger";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger); 
}

export default function InternalShapes({page, widget}) {
	
  /**
   * Tells us if the current page / widget compo has shapes
   */
  const hasShapes = useCallback(() => {
	//console.log(page, widget);
    //this is the list of pages where we have internal shapes
	const pagesWithShapes = [
	  ["about-us", "HorizontalLine"],
	  ["careers", "Cta"],
	  ["careers", "HowWeDoThings"],
	  ["existing-borrowers", "Cta"],
	  ["bdm-finder", "Bdm"],
	  ["lending-criteria", "SidebarPage"],
	  ["conveyancers", "Conveyancers"],
	  ["widgets", "Faq"],
	  ["placing-business", "Faq"],
    ];

    let hasShapes = false;
	
	pagesWithShapes.forEach(item => {
	  if (item[0]===page && item[1]===widget) {
		hasShapes = true;	
	  }
	});
	return hasShapes;
  }, [page, widget]);
	
  const trigger = useRef();
  const shape1 = useRef();
  const shape2 = useRef();
  const shape3 = useRef();

  //do we want to animate the shapes?
  useEffect(() => {
	
	if (hasShapes()) {
	  const tl = gsap.timeline({
        scrollTrigger: {
            trigger: trigger.current,
            //markers: true,
            start: "top bottom",
            end: "bottom top",
            invalidateOnRefresh: true,//https://greensock.com/st-mistakes/#update-refresh
            scrub: true,
	    }
      });
	  tl.to(shape1.current, {y: -100})
        .to(shape2.current, {y: -200}, 0)
        .to(shape3.current, {y: -300}, 0);
	}
  }, [hasShapes]);	

  if (!hasShapes()) {
	return null;
  }
	
  return(
	<div className="internal-shapes" ref={trigger}>
		<span className="internal-shape internal-shape1" ref={shape1} />
		<span className="internal-shape internal-shape2" ref={shape2} />
		<span className="internal-shape internal-shape3" ref={shape3} />
	</div>
  )
}

