import React, {useState} from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '../Button';
import Accordion from '../Accordion';

export default function Content({widget}) {

  const [activeImage, setActiveImage] = useState(widget.image.image);

	/*fieldGroupName
      layout
	  image {
	    image {
          localFile {
		    childImageSharp {
	           gatsbyImageData(
				 sizes: "(min-width: 1280px) 616px, (min-width: 768px) calc(50vw - 24px), 284px",
				 breakpoints: [568, 1232]
	           )
	         }
          }
        }
		title
		description
      }
	  copy {
		subtitle
        title
        accordion {
          text
          title
	      image {
            localFile {
		      childImageSharp {
	            gatsbyImageData(
			      sizes: "(min-width: 1280px) 616px, (min-width: 768px) calc(50vw - 24px), 284px",
				  breakpoints: [568, 1232]
	            )
	          }
            }
          }
        }
        text
        button {
          target
          title
          url
        }
      }
      */

  const onAccordionChange = function(index) {
	//do we have a custom image for this index?
	if (widget.copy.accordion[index].image) {
		setActiveImage(widget.copy.accordion[index].image);
	} else {
		setActiveImage(widget.image.image);
	}
  }

  const getWidgetClasses = function(layout) {
	if (layout==='grey') {
		return 'widget content grey';
	}
	return 'widget content white-bg '+layout;
  }

  const getButtonClasses = function(layout) {
	if (layout==='grey') {
		return 'solid black';
	}
	return 'solid purple';
  }

  return(
	<section className={getWidgetClasses(widget.layout)}>
	  <div className="internal">
		<div className="image">
		  {
			activeImage?.localFile
			? <GatsbyImage image={getImage(activeImage.localFile)} alt={widget.copy.title} />
			: null
		  }
		  <figcaption>
		    <span className="title">{widget.image.title}</span>
		    <span className="description">{widget.image.description}</span>
	      </figcaption>
		</div>
		<div className="copy">
		  {
			widget.copy.subtitle
			? <p className="subtitle">{widget.copy.subtitle}</p>
			: null
		  }
				
		  <h2>{widget.copy.title}</h2>
		
		  {
			widget.copy?.accordion?.length
			? <Accordion items={widget.copy.accordion} callback={(index) => onAccordionChange(index)} />
			: null
		  }
		
		  {widget.copy.text && (
		    <div className="text" dangerouslySetInnerHTML={{ __html: widget.copy.text }} />
		  )}
		
		  {
		    widget.copy.button
		    ? <Button link={widget.copy.button} className={getButtonClasses(widget.layout)} />				  
		    : null
		  }			
		</div>
	  </div>
	</section>
  )
}

