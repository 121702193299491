import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Button from '../Button';
import DownloadIcon from "../../assets/svg/inline/ic_download.svg"

export default function Downloads({widget}) {
	/*
	  fieldGroupName
      title
      blocks {
        title
        downloads {
          download
        }
		button {
            target
            title
            url
  		}
      }*/

  //grab the downloads from the options page
  const downloads = useStaticQuery(graphql`
    query {
	  wp {
		acfOptionsOptions {
	      options {
	        widgetDownloads {
	          title
	          url
	        }
	      }
	    }
	  }
    }
  `)

    return(
		<section className="widget downloads internal white-bg">	
			<h2>{widget.title}</h2>
			<div className="downloads-list">
				{widget.blocks?.map((block, i) => (
				  <div className="block" key={`block${i}`}>
					  {
						block.title
						? <h3>{block.title}</h3>
						: null
					  }
					  <ul>
						  {block.downloads?.map(({download}, j) => {
							//loop through the downloads from the options page and look for a match
							return (downloads?.wp?.acfOptionsOptions?.options?.widgetDownloads?.map((optionDownload) => {
						      return (download===optionDownload.title)
							  ? <li key={`download${j}`}>
									<a href={optionDownload.url} target="_blank" rel="noreferrer">
										<span>{optionDownload.title}</span>
										<DownloadIcon />
									</a>
							 	</li>
					          : null
						    }))
						  })}
				      </ul>
				  </div>
				))}
			</div>	
			{
			  widget.button
			  ? <p className="buttonp"><Button link={widget.button} className="solid purple" /></p>			  
			  : null
			}		
		</section>
    )
}

