import React, {useCallback, useState} from "react";
import Tabs from '../Tabs';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LinkedInImg from "../../assets/svg/inline/ic_linkedin.svg"
import CloseIcon from "../../assets/svg/inline/ic_close.svg"

export default function LandbayExecTeam({widget}) {
	/*fieldGroupName
      title
      intro
      executiveTeam {
        photo {
          id
        }
        linkedin
        jobTitle
        name
      }
      boardMembers {
        photo {
          id
        }
        popupPhoto {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        bio
        linkedin
        jobTitle
        name
      }*/

  const [activeProfile, setActiveProfile] = useState(false);

  const getTabContent = useCallback((index) => {	
	
	let tabData = false,
		tab = false;
	
	switch(index) {
		case 1:
			tabData = widget.boardMembers;
			tab = 'board';
			break
		default:
			tabData = widget.executiveTeam;
			tab = 'exec';
			break
	}
	
	if (tabData) {
		return(
			<div className="team-members">
			    {tabData.map((item, i) => (
				    <div className="team-member" key={`team-member${i}`}>
						<button className="plain" onClick={() => setActiveProfile([tab, i])}>
							<GatsbyImage image={getImage(item.photo.localFile)} alt={item.name} />
							<div className="details">
								<h3>{item.name}</h3>
								<p>{item.jobTitle}</p>
							</div>
						</button>
						{
							item.linkedin
							? <a href={item.linkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn"><LinkedInImg /></a>
							: null
						}
				    </div>
			    ))}
			</div>
		)
	}
  }, [widget]);

  const getActiveProfile = function() {
	if (activeProfile) {
		let tabData = false;
		if (activeProfile[0]==='board') {
			tabData = widget.boardMembers;
		} else {
			tabData = widget.executiveTeam;
		}
		if (tabData) {
			return tabData[activeProfile[1]];
		}
	}
  }

/*
  const onClickRow = useCallback((index) => {	
	if (activeRow===index) {
		setActiveRow(false);
	} else {
		setActiveRow(index);
	}
  }, [activeRow]);*/

  const tabTitles = ['Executive Team', 'Board members'];

  var profile = getActiveProfile();

    return(
		<section className={activeProfile ? 'widget landbay-exec-team white-bg expose' : 'widget landbay-exec-team white-bg'}>	
			<div className="internal">
				<div className="top">
					<h2>{widget.title}</h2>
					{
					  widget.intro
					  ? <div className="intro" dangerouslySetInnerHTML={{ __html: widget.intro }} />
					  : null
					}
				</div>
				
				<Tabs label="Executive Team Tabs" tabTitles={tabTitles} getTabContent={getTabContent} />
			</div>
			{profile && (
				<div className="popup-wrap">
					<div className="popup">
						<GatsbyImage image={getImage(profile.popupPhoto.localFile)} alt={profile.name} />
						<div className="content">
						  <button className="plain close" onClick={() => setActiveProfile(false)} aria-label="close"><CloseIcon /></button>
							<h3>{profile.name}</h3>
							<p className="job-title">{profile.jobTitle}</p>
							<div className="bio" dangerouslySetInnerHTML={{ __html: profile.bio }} />
							{
								profile.linkedin
								? <a href={profile.linkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn" className="linkedin"><LinkedInImg /></a>
								: null
							}
						</div>
					</div>
				</div>
			)}
		</section>
    )
}

