import React from "react";
import PostPreview from '../PostPreview' 

export default function RelatedArticles({widget}) {
	/*
	  layout
      title
      articles {
        ... on WpPost {
          ...PostFragment
        }
      }*/

	const layout = widget.layout ? widget.layout : 'white-bg';

    return(
		<section className={`widget related-articles ${layout}`}>	
			<div className="internal">
	        	<h2>{widget.title}</h2>
				<div className="articles">					
					{widget?.articles?.length>0 && widget.articles.map((article, i) => (
					  <PostPreview key={`article${i}`} post={article} />
				    ))}					
				</div>
			</div>
		</section>
    )
}

