import React, { useState, useCallback } from "react";

export default function Tabs({label, tabTitles, getTabContent, setParentActiveTab=false}) {	
	
  const [activeTab, setActiveTab] = useState(0);

  const onClickTab = useCallback((index) => {	
	setActiveTab(index);	
	if (setParentActiveTab) {
		setParentActiveTab(index);	
	}
  }, [setParentActiveTab]);

  return(
	  <div className="landbay-tabs">
		  <div className={tabTitles.length>2 ? 'tablist wide' : 'tablist'} role="tablist" aria-label={label}>		    
			{tabTitles?.map((tabTitle, i) => (
				<button key={`tab${i}`} className={activeTab===i ? 'plain active' : 'plain'} onClick={() => onClickTab(i)} onKeyDown={() => onClickTab(i)} role="tab" aria-selected="true" aria-controls={`panel-${i}`} id={`tab-${i}`} tabIndex="0">
	          	  {tabTitle}
	            </button>
			))}
		  </div>
		  {tabTitles?.map((tabTitle, i) => (
			<div key={`tab-content${i}`} className={activeTab===i ? 'tab-content active' : 'tab-content'} id={`panel-${i}`} role="tabpanel" aria-labelledby={`tab-${i}`}>
				{getTabContent(i)}
			 </div>
		  ))}
		  
	  </div>
  )
}

