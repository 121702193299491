import React, { useCallback } from "react";
import Tabs from '../Tabs';
import Button from '../Button';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function CareersTabs({widget}) {
	/*fieldGroupName
      careersTabsTitle
      careersTabs {
        tabTitle
        image {
          localFile {
            childImageSharp {
              sizes: "(min-width: 768px) 476px, calc(100vw - 32px)",
			  breakpoints: [686, 952]
            }
          }
        }
        copy {
          quoteText
          quoteSourceName
          quoteSourceCompany
          copy
          button {
            target
            title
            url
          }
        }
      }*/


  const getTabContent = useCallback((index) => {	
	
	if (index<widget.careersTabs.length) {
	
		const tabData = widget.careersTabs[index];
		
		if (tabData) {
			return(
				<div className="career-tab internal">
					<div className="copy">
						{tabData.copy.quoteText && (
							<figure>
							    <blockquote>
							        <p dangerouslySetInnerHTML={{ __html: tabData.copy.quoteText }} />
							    </blockquote>
							    <figcaption>
									{tabData.copy.quoteSourceName}
									{
										tabData.copy.quoteSourceCompany
										? <cite>, {tabData.copy.quoteSourceCompany}</cite>
										: null
									}
								</figcaption>
							</figure>
						)}
						{tabData.copy.copy && (
						    <div className="text" dangerouslySetInnerHTML={{ __html: tabData.copy.copy }} />
						)}
						{tabData.copy.button && (
						    <p className="buttonp"><Button link={tabData.copy.button} className="solid purple" /></p>
						)}
					</div>
					<div className="image">
						{tabData.image?.localFile && (
							<GatsbyImage image={getImage(tabData.image.localFile)} alt={tabData.image.altText} />
						)}
					</div>
				</div>
			)
		}
	}
	return null;
  }, [widget]);


  const getTabTitles = useCallback(() => {	
	var tabTitles = [];
	widget.careersTabs.forEach(function(tab) {
	   tabTitles.push(tab.tabTitle);
    })
	return tabTitles;
  }, [widget]);

    return(
		<section className="widget careers-tabs white-bg">	
			<div className="internal">
				<h2>{widget.careersTabsTitle}</h2>
			</div>
				
			<Tabs label="Landbay Departments Tabs" tabTitles={getTabTitles()} getTabContent={getTabContent} />
		</section>
    )
}

