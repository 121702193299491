import React from "react";

export default function USPBar({widget}) {
	/*fieldGroupName
      usps {
        subtitle
        title
      }*/
    return(
		<section className="widget usp-bar internal white-bg">	
	        {widget.usps?.length && (
			  <div className="usps">
				{widget.usps.map((usp, i) => (
				  <div className="usp" key={`usp${i}`}>
					<h3>{usp.title}</h3>
					<p>{usp.subtitle}</p>
				  </div>
				))}
			  </div>
			)}	
		</section>
    )
}

