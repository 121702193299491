import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '../Button';
import Accordion from '../Accordion';

export default function Process({widget}) {
	/*
	  fieldGroupName
	  layout
      copy {
        title
        intro
        image {
          id
        }
        finishedTitle
        button {
          target
          title
          url
        }
      }
	  colour
      accordion {
        subtitle
        text
		subtitle2
        title
      }*/

  const Intro = () => (
    <div className="title">
	  <h2>{widget.copy.title}</h2>
	  <div className="intro" dangerouslySetInnerHTML={{ __html: widget.copy.intro }} />
    </div>
  );

  const Bottom = () => (
    <div className="bottom">
	  {
	    widget.copy.finishedTitle
	    ? <p className="finished">{widget.copy.finishedTitle}</p>		  
	    : null
	  }
	  {
	    widget.copy.button
	    ? <p className="buttonp"><Button link={widget.copy.button} className="solid purple" /></p>			  
	    : null
	  }
    </div>
  );

    return(
		<section className={`widget process internal white-bg ${widget.layout}`}>
			{
				widget.layout==='copy_centred'
				? <Intro />
				: null
			}
				
        	<div className="content">

	        	<div className="copy">
					{
						widget.layout==='copy_left'
						? <Intro />
						: null
					}
					{
					  widget.copy.image
						? <GatsbyImage image={getImage(widget.copy.image.localFile)} alt={widget.copy.title} />
						: null
					}
				</div>
	        	<div className={`accordion ${widget.colour}`}>
					<Accordion items={widget.accordion} />
				</div>
			</div>
			<Bottom />
		</section>
    )
}

