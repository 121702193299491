import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import TrustBox from "./TrustBox"

export default function Trustpilot({widget}) {

  //grab the awards from the options page
  const trustpilotWidgets = useStaticQuery(graphql`
    query {
	  wp {
	    acfOptionsOptions {
	      options {
	        widgetTrustpilot {
	          name
	          embedCode
	        }
	      }
	    }
	  }
    }
  `)

  //based on the values selected in the pulldown for this widget, we look at what's been set up on the options page
  const getTrustpilotWidget = function() {
	let matched = false,
		options = trustpilotWidgets?.wp?.acfOptionsOptions?.options?.widgetTrustpilot;
	if (widget.trustpilotWidget && options) {
	  options.forEach(function(option) {
		if (widget.trustpilotWidget===option.name) {
		  matched = option.embedCode
		}
	  })
	}
	
	if (matched) {
		return matched;
	}
	
	//not found
	return null;
  }

  const extractAttributes = function(embedCode) {
	if (typeof window !== "undefined") {
	let parser = new DOMParser(),
		doc = parser.parseFromString(embedCode, "text/html");
	if (doc) {
		let trustpilot = doc.querySelector('.trustpilot-widget');
		
		if (trustpilot) {
		
		  let a = [].filter.call(trustpilot.attributes, function(at) { 
			return at.name!=='class'; 
		  });
		
		  if (a.length) {			
		    let attributes = {};
		    a.forEach(function(attribute) {
		      attributes[attribute.name] = attribute.value
	        })
		    return attributes;
		  }
		}
	  }
	}
	return false;
  }

  const getAttributes = function() {
	let trustpilot = getTrustpilotWidget();
	if (trustpilot) {
	  return extractAttributes(trustpilot);
	}
	return false;
  }

  const attributes = getAttributes();

  return (
	<section className="widget trustpilot internal white-bg">
	  <TrustBox attributes={attributes} />
	</section>
  );
}

