import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Button from '../Button';

export default function Articles({widget}) {
	/*fieldGroupName
      title
      type
      articles {
        ... on WpPost {
          id
          title
          excerpt
          link
        }
      }
      category {
        id
      }*/

  //query for 'latest posts'
  const latestPosts = useStaticQuery(graphql`
    query {
      allWpPost(sort: {fields: [date], order: DESC}, limit: 3) {
	    edges {
	      node {
	        uri
	        title
	        excerpt
	      }
	    }
	  }
    }
  `)

  /*
  Can't do this - can't use variables in graphql'
  const categoryQuery = useStaticQuery(graphql`
    query {
      allWpPost(
	    sort: {fields: [date], order: DESC}, 
	    limit: 3,
        filter: {categories: {nodes: {elemMatch: {name: {eq: "${widget.category.name}"}}}}}
      ) {
        edges {
	      node {
	        uri
	        title
	        excerpt
	      }
	    }
  	  }
    }
  `)*/

  //latest posts
  const LatestPosts = () => (
    <>
      {latestPosts?.allWpPost?.edges?.length && latestPosts.allWpPost.edges.map(({node}, i) => (
		  <article key={`article${i}`}>
			<h3>{node.title}</h3>
			<div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
			<p className="buttonp">
				<Button link={{url: node.uri, title: 'View'}} className="solid purple small" />	
			</p>
		  </article>
	  ))}
    </>
  );

/*
  //latest posts in selected category
  const CategoryPosts = () => (
    <>
      {latestPosts?.allWpPost?.edges?.length && latestPosts.allWpPost.edges.map(({node}, i) => (
		  <article key={`article${i}`}>
			<h3>{node.title}</h3>
			<div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
			<p className="buttonp">
				<Button link={{url: node.uri, title: 'View'}} className="solid purple small" />	
			</p>
		  </article>
	  ))}
    </>
  );*/

  //manually-selected posts
  const ManualPosts = () => (
    <>
      {widget?.articles?.length && widget.articles.map((article, i) => (
		  <article key={`article${i}`}>
			<h3>{article.title}</h3>
			<div className="excerpt" dangerouslySetInnerHTML={{ __html: article.excerpt }} />
			<p className="buttonp">
				<Button link={{url: article.link, title: 'View'}} className="solid purple small" />	
			</p>
		  </article>
	  ))}
    </>
  );

  return(
	<section className="widget articles">	
		<div className="internal">
			<h2>{widget.title}</h2>
			
			  <div className="articles">
				{/*Depending on the type selected we use a different sub-component to display it*/}
				
				{
			      {
		            'latest': <LatestPosts />,
		            'manual': <ManualPosts />
			      }[widget.type]
			    }
				
			  </div>
			
		</div>
	</section>
  )
}

