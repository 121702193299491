import React, { Fragment, useState, useCallback } from "react";
import Collapse from "@kunukn/react-collapse";

export default function Accordion({items, callback=false}) {
	
  const [activeRow, setActiveRow] = useState(0);

  const onClickRow = useCallback((index) => {	
	var newIndex = index;
	if (activeRow===index) {
		newIndex = false;
	}
	setActiveRow(newIndex)
	if (callback) {
		callback(newIndex);
	}
  }, [activeRow, callback]);

  return(
	  <dl className="landbay-accordion">
		{items?.length && items.map((row, i) => (
		  <Fragment key={`${i}-item`}>
			<div className={activeRow===i ? 'active' : ''}>{/*ref: https://github.com/whatwg/html/issues/1937*/}
				<dt onClick={() => onClickRow(i)} onKeyDown={() => onClickRow(i)} role="presentation">
				  {
					row.subtitle
					? <span className="subtitle">{row.subtitle}</span>
					: null
				  }
				  {row.title}
				  {
					row.subtitle2
					? <span className="subtitle2">{row.subtitle2}</span>
					: null
				  }
				</dt>
				<dd><Collapse isOpen={activeRow===i} dangerouslySetInnerHTML={{ __html: row.text }}></Collapse></dd>
			</div>
		  </Fragment>
		))}
	  </dl>
  )
}

