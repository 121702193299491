import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from '../Button';
import InternalShapes from "./InternalShapes";

export default function Cta({widget, page}) {
	/*
	fieldGroupName
      layout
      copy {
        title
        text
        buttons {
          button {
            target
            title
            url
          }
        }
        footerText
      }
      image {
        title
        description
        image {
          id
        }
		mobileImage {
          localFile {
		    childImageSharp {
	           gatsbyImageData(
			      sizes: "88px",
				  breakpoints: [176]
	           )
	         }
          }
        }
      }*/

  const Cta50 = () => (
	<>
	  <InternalShapes page={page} widget="Cta" />
	  <div className="columns">
	    	<figure className="image">
	{/*}
			<GatsbyImage
	        layout="fullWidth"
	        // You can optionally force an aspect ratio for the generated image
	        aspectRatio={3 / 1}
	        // This is a presentational image, so the alt should be an empty string
	        alt=""
	        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
	        src={
	          "https://images.unsplash.com/photo-1604975999044-188783d54fb3?w=2589"
	        }
	        formats={["auto", "webp", "avif"]}
	      />*/}
	
				<GatsbyImage image={getImage(widget.image.image.localFile)} alt={widget.copy.title} />
				<ImageTitle />
			</figure>
			<div className="copycol">
	    		<Copy />
	    	</div>
	  </div>
	</>
  );

  const Cta100 = () => (
	<>
		{
			widget.image.mobileImage
			? <GatsbyImage image={getImage(widget.image.mobileImage.localFile)} alt={widget.copy.title} className="mobile" />
			: null
		}
    	<Copy />
		<GatsbyImage image={getImage(widget.image.image.localFile)} alt={widget.copy.title} className="desktop" />
		<ImageTitle />
	</>
  );

  const CtaBasic = () => (
	<>
	  <Copy />
	  <InternalShapes page={page} widget="Cta" />
	</>
  );

  const Copy = () => (
	<div className="copy">
  		<h2>{widget.copy.title}</h2>
		{
		  widget.copy.text
  		  ? <div className="text" dangerouslySetInnerHTML={{ __html: widget.copy.text }} />			  
		  : null
		}	
	    <p className="buttonp">
		  	{widget.copy.buttons?.length && widget.copy.buttons.map((button, i) => (
			  <Button key={`button${i}`} link={button.button} className={buttonStyles[i]} />
			))}
	    </p>
		{
		  widget.copy.footerText
  		  ? <div className="footer" dangerouslySetInnerHTML={{ __html: widget.copy.footerText }} />			  
		  : null
		}	
	</div>
  );

  const ImageTitle = () => (
    <figcaption>
	  <span className="title">{widget.image.title}</span>
	  <span className="description">{widget.image.description}</span>
    </figcaption>
  );

    const buttonStyles = ['purple solid', 'white solid'];

    return(
		<section className={`widget cta ${widget.layout}`}>
    		<div className="internal">
				{
			      {
		            'two_columns': <Cta50 />,
		            'full_width': <Cta100 />,
		            'basic': <CtaBasic />,
		            'basic_yellow': <CtaBasic />
			      }[widget.layout]
			    }
		    </div>
		</section>
    )
}

