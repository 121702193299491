import React from "react";
import Button from '../Button';

export default function QuickLinkCards({widget}) {
  /*cards {
    title
    button {
      target
      title
      url
    }
  }*/
  return(
	<section className="widget quicklinkcards internal white-bg">	
		{widget.cards?.length && (
		  <div className="cards">
			{widget.cards.map((card, i) => (
			  <div className="card" key={`card${i}`}>
				<h3>{card.title}</h3>
				<p>
					<Button link={card.button} className="solid purple small" />	
				</p>
			  </div>
			))}
		  </div>
		)}	
	</section>
  )
}

