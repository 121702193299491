import React from "react";
import { graphql } from "gatsby"
import { Hero, Trustpilot, Content, AwardBar, QuickLinkCards, Articles, Process, Cta, 
QuickLinks, Faq, Partners, Quote, ProductRange, ProductRangeManual, LandbayExecTeam, FeaturedProduct, USPBar, 
Benefits, Downloads, RelatedArticles, Careers, CareersTabs, HowWeDoThings, HorizontalLine, Sitemap, Video } from './'
import PostPreview from '../PostPreview'

export default function Widgets({widgets, page}) {
	if (!widgets?.length) {
		return null;
	}
	return(
		<div className="page-widgets">
	      {/*<div dangerouslySetInnerHTML={{ __html: post.content }} />*/}
		  {widgets.map((widget, index) => {
			switch(widget.__typename) {
		     case 'WpPage_Widgets_PageWidgets_Hero': return (
		         <Hero key={ index } widget={ widget } />
		     );	
		     case 'WpPage_Widgets_PageWidgets_Trustpilot': return (
		         <Trustpilot key={ index } widget={ widget } />
		     );		
		     case 'WpPage_Widgets_PageWidgets_Content': return (
		         <Content key={ index } widget={ widget } />
		     );	 
		     case 'WpPage_Widgets_PageWidgets_AwardBar': return (
		         <AwardBar key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_QuickLinkCards': return (
		         <QuickLinkCards key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Articles': return (
		         <Articles key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Process': return (
		         <Process key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Cta': return (
		         <Cta key={ index } widget={ widget } page={page} />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_QuickLink': return (
		         <QuickLinks key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Faq': return (
		         <Faq key={ index } widget={ widget } page={page} />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Partners': return (
		         <Partners key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Quote': return (
		         <Quote key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_ProductRange': return (
		         <ProductRange key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_ProductRangeManual': return (
		         <ProductRangeManual key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_LandbayExecTeam': return (
		         <LandbayExecTeam key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_FeaturedProduct': return (
		         <FeaturedProduct key={ index } widget={ widget } />
		     );	 
		     case 'WpPage_Widgets_PageWidgets_UspBar': return (
		         <USPBar key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_Benefits': return (
		         <Benefits key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_PopularDownloads': return (
		         <Downloads key={ index } widget={ widget } />
		     );		 
		     case 'WpPage_Widgets_PageWidgets_RelatedArticles': return (
		         <RelatedArticles key={ index } widget={ widget } />
		     );			 
		     case 'careers': return (
		         <Careers key={ index } widget={ widget } />
		     );	   		 
		     case 'careersTabs': return (
		         <CareersTabs key={ index } widget={ widget } />
		     );	  
		     case 'WpPage_Widgets_PageWidgets_HowWeDoThings': return (
		         <HowWeDoThings key={ index } widget={ widget } page={page} />
		     );	
		     case 'WpPage_Widgets_PageWidgets_HorizontalLine': return (
		         <HorizontalLine key={ index } page={page} />
		     );	
		     case 'WpPage_Widgets_PageWidgets_Sitemap': return (
		         <Sitemap key={ index } widget={ widget } />
		     );		
		     case 'WpPage_Widgets_PageWidgets_Video': return (
		         <Video key={ index } widget={ widget } />
		     );	 
		     default: return (<p style={{color: "red"}}>Widget: {widget.__typename}</p>)
		   }
	      })}
	    </div>
	)
}

export const WidgetFragment = graphql`
	fragment WidgetFragment on WpPage {
	  widgets {
	      pageWidgets {
			__typename
	        ... on WpPage_Widgets_PageWidgets_Hero {
	          layout
	          title
	          subtitle
			  content
	          buttons {
	            button {
	              target
	              title
	              url
	            }
	          }
			  images {
				mobileImage {
		          localFile {
		            childImageSharp {
		              gatsbyImageData(
		                sizes: "100vw"
		                breakpoints: [750]
		              )
		            }
		          }
		          altText
		        }
		        desktopImage {
		          localFile {
		            childImageSharp {
		              gatsbyImageData(
		                sizes: "(min-width: 768px) 1280px"
		                breakpoints: [2560]
		              )
		            }
		          }
		          altText
		        }
	          }
	        }
			... on WpPage_Widgets_PageWidgets_Trustpilot {
			  trustpilotWidget
			}
        	... on WpPage_Widgets_PageWidgets_Content {
	          layout
			  image {
			    image {
                  localFile {
				    childImageSharp {
			           gatsbyImageData(
						 sizes: "(min-width: 1280px) 616px, (min-width: 768px) calc(50vw - 24px), 284px",
						 breakpoints: [568, 1232]
			           )
			         }
                  }
                }
				title
				description
              }
			  copy {
				subtitle
	            title
	            accordion {
	              text
	              title
			      image {
                    localFile {
				      childImageSharp {
			            gatsbyImageData(
						 sizes: "(min-width: 1280px) 500px, (min-width: 768px) calc(50vw - 24px), 284px",
						 breakpoints: [568, 1000]
			            )
			          }
                    }
                  }
	            }
        		text
	            button {
	              target
	              title
	              url
	            }
	          }
	        }
        	... on WpPage_Widgets_PageWidgets_Video {
	          layout
			  video {
			    image {
                  localFile {
				    childImageSharp {
			           gatsbyImageData(
						 sizes: "(min-width: 1280px) 500px, (min-width: 768px) calc(50vw - 24px), 284px",
						 breakpoints: [568, 1000]
			           )
			         }
                  }
                }
				url
				hash
              }
			  copy {
				subtitle
	            title
	            accordion {
	              text
	              title
	            }
        		text
	            button {
	              target
	              title
	              url
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_AwardBar {
	          title
	          awards {
	            award
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_QuickLinkCards {
	          cards {
	            title
	            button {
	              target
	              title
	              url
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Articles {
	          title
	          type
	          articles {
	            ... on WpPost {
	              id
	              title
	              excerpt
	              link
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Process {
			  layout
	          copy {
	            title
	            intro
	            image {
	              localFile {
				    childImageSharp {
			           gatsbyImageData(
					      sizes: "(min-width: 1280px) 616px, (min-width: 768px) calc(50vw - 24px), calc(100vw - 64px)",
						  breakpoints: [624, 720, 1232]
			           )
			         }
                  }
	            }
	            finishedTitle
	            button {
	              target
	              title
	              url
	            }
	          }
			  colour
	          accordion {
	            subtitle
	            text
			    subtitle2
	            title
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Cta {
	          layout
	          copy {
	            title
	            text
	            buttons {
	              button {
	                target
	                title
	                url
	              }
	            }
	            footerText
	          }
	          image {
	            title
	            description
	            image {
	              localFile {
				    childImageSharp {
			           gatsbyImageData(
					      sizes: "(min-width: 768px) 1280px, 100vw",
						  breakpoints: [750, 2560]
			           )
			         }
                  }
	            }
				mobileImage {
	              localFile {
				    childImageSharp {
			           gatsbyImageData(
					      sizes: "88px",
						  breakpoints: [176]
			           )
			         }
                  }
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_QuickLink {
	          title
	          links {
	            link {
	              url
	              title
	              target
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Faq {
			  layout
	          searchBoxPlaceholder
	          title
	          accordion {
	            title
	            text
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Partners {
	          title
	          intro
	        }
	        ... on WpPage_Widgets_PageWidgets_Quote {
	          layout
			  copy {
	            text
	            quoteSourceCompany
	            quoteSourceName
			  }
			  images {
		          image {
		            localFile {
					  childImageSharp {
				        gatsbyImageData(
						  sizes: "(min-width: 768px) 1280px, 100vw",
						  breakpoints: [2560]
				        )
				      }
		            }
					altText
		          }
		          mobileImage {
		            localFile {
					  childImageSharp {
				        gatsbyImageData(
						  sizes: "88px",
						  breakpoints: [176]
				        )
				      }
	                }
					altText
		          }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_ProductRange {
			  layout
	          title
	          button {
	            target
	            title
	            url
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_ProductRangeManual {
			  layout
	          title
			  products {
				title
	            ratesFrom
	            minLoan
	            maxLtvFrom
	            maxLoan
	            link {
	              title
	              target
	              url
	            }
			  }
	          button {
	            target
	            title
	            url
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_LandbayExecTeam {
	          title
	          intro
	          executiveTeam {
	            photo {
	              localFile {
				    childImageSharp {
			           gatsbyImageData(
						sizes: "(min-width: 1280px) 288px, (min-width: 768px) calc(100vw - 32px), 81px",
						breakpoints: [162, 576, 788]
			           )
			         }
                  }
	            }
	            linkedin
	            jobTitle
	            name
	            popupPhoto {
	              localFile {
	                childImageSharp {
	                  gatsbyImageData(
						sizes: "(min-width: 568px) 568px, calc(100vw - 32px)",
						breakpoints: [686, 1136]
					  )
	                }
	              }
	            }
	            bio
	          }
	          boardMembers {
	            photo {
	              localFile {
				    childImageSharp {
			           gatsbyImageData(
						sizes: "(min-width: 1280px) 288px, (min-width: 768px) calc(100vw - 32px), 284px",
						breakpoints: [568, 576, 788]
			           )
			         }
                  }
	            }
	            linkedin
	            jobTitle
	            name
	            popupPhoto {
	              localFile {
	                childImageSharp {
			          gatsbyImageData(
						sizes: "(min-width: 568px) 568px, calc(100vw - 32px)",
						breakpoints: [688, 1024, 1470]
					  )
	                }
	              }
	            }
	            bio
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_FeaturedProduct {
	          copy {
	            newSticker
	            title
	            intro
	            button {
	              target
	              title
	              url
	            }
	          }
	          product {
	            backgroundColour
	            source
	            landbayProduct
	            manual {
	              line1
	              line2
	              line3
	              line4
	              line5
	              line6
	              line7
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_UspBar {
	          usps {
	            subtitle
	            title
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_Benefits {
	          intro
	          title
	          benefits {
	            title
	            copy
	            image {
	              localFile {
				    childImageSharp {
			          gatsbyImageData(
					    sizes: "240px",
			            breakpoints: [480]
			          )
			        }
                  }
	            }
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_PopularDownloads {
	          title
	          blocks {
	            title
	            downloads {
              	  download
            	}
	          }
	          button {
	            target
	            title
	            url
	          }
	        }
	        ... on WpPage_Widgets_PageWidgets_RelatedArticles {
			  layout
	          title
	          articles {
	            ... on WpPost {
				  ...PostFragment	              
	            }
	          }
	        }
			... on WpPage_Widgets_PageWidgets_HowWeDoThings {
	          layout
	          title
	          blocks {
	            image {
	              localFile {
				    childImageSharp {
			          gatsbyImageData(
					    sizes: "240px",
			            breakpoints: [480]
			          )
			        }
                  }
	            }
	            title
	            copy
	          }
	        }
	      }
		}
	}
`
Widgets.fragments = [PostPreview];//works without this but this stops the unused variable warning
