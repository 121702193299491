import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TrustBox from "./TrustBox"

export default function AwardBar({widget}) {
  /*
  title
  awards {
	award
  }
  */

  //grab the awards from the options page
  const awards = useStaticQuery(graphql`
    query {
	  wp {
		acfOptionsOptions {
	      options {
	        widgetAwards {
	          title
	          url
	          image {
	            localFile {
			      childImageSharp {
		            gatsbyImageData(
		              placeholder: BLURRED
					  formats: [PNG]
		            )
		          }
	            }
	            mediaDetails {
	              width
	              height
	            }
	          }
	        }
	      }
	    }
	  }
    }
  `)

  const getImageStyle = function(image) {
	let width=image?.mediaDetails?.width,
		height=image?.mediaDetails?.height;
	if (width && height) {
		return {width: Math.floor(width/2), height: Math.floor(height/2)};
	}
	return null;
  }

  return(
	<section className="widget awardbar internal white-bg">	
		{
		  widget.title
		  ? <h2>{widget.title}</h2>
		  : null
		}
		<div className="awards">
		  {widget.awards && widget.awards.length && widget.awards.map(({award}, i) => {
			if (award==='glassdoor') {
			  return (<div className="award glassdoor" key={`award${i}`}><a href='https://www.glassdoor.co.uk/Overview/Working-at-Landbay-EI_IE2431186.11,18.htm'><img src='https://www.glassdoor.co.uk/api/widget/verticalStarRating.htm?e=2431186' alt="Glassdoor rating" /></a></div>)
			} else if (award==='trustpilot') {
			  return (
				<div className="award trustpilot" key={`award${i}`}>
				  <TrustBox templateId="53aa8807dec7e10d38f59f32" height="150px" />
				</div>
			  )
			}
			//otherwise loop through the awards from the options page and look for a match
			return (awards?.wp?.acfOptionsOptions?.options?.widgetAwards?.map((optionAward) => {
			  return (award===optionAward.title && optionAward.image?.localFile) 
			  ? <div className="award" key={`award${i}`}>
				  {
					optionAward.url
					? <a href={optionAward.url} target="_blank" rel="noreferrer"><GatsbyImage image={getImage(optionAward.image.localFile)} alt={optionAward.title} style={getImageStyle(optionAward.image)} /></a>
					: <GatsbyImage image={getImage(optionAward.image.localFile)} alt={optionAward.title} style={getImageStyle(optionAward.image)} />
				  }
			    </div>
			  : null
		    }))
		  })}
		</div>
	</section>
  )
}

