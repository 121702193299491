import React, { Fragment, useState, useCallback } from "react";
import Collapse from "@kunukn/react-collapse";
import InternalShapes from "./InternalShapes";

export default function Faq({widget, page}) {
	/*
	  layout
      searchBoxPlaceholder
      title
      accordion {
        title
        text
      }*/
  const [activeRow, setActiveRow] = useState(0);

  const onClickRow = useCallback((index) => {	
	if (activeRow===index) {
		setActiveRow(false);
	} else {
		setActiveRow(index);
	}
  }, [activeRow]);

    return(
		<section className={`widget faq ${widget.layout}`}>
			<div className="internal">	
	        	<h2>{widget.title}</h2>
				<form action="https://help.landbay.co.uk/kb-search-results" target="_blank">
					<input type="text" name="term" autoComplete="off" placeholder={widget.searchBoxPlaceholder} required />
					<button type="submit" className="plain" aria-label="Submit search"></button>
				</form>
	  			<InternalShapes page={page} widget="Faq" />
				<div className="faqs">
					<dl className="landbay-accordion">
						{widget.accordion?.length && widget.accordion.map((row, i) => (
						  <Fragment key={`${i}-item`}>
							<div className={activeRow===i ? 'active' : ''}>{/*ref: https://github.com/whatwg/html/issues/1937*/}
								<dt onClick={() => onClickRow(i)} onKeyDown={() => onClickRow(i)} role="presentation">
								  {row.title}
								</dt>
								<dd><Collapse isOpen={activeRow===i} dangerouslySetInnerHTML={{ __html: row.text }}></Collapse></dd>
							</div>
						  </Fragment>
						))}
				    </dl>
				</div>
			</div>
		</section>
    )
}

