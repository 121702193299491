import React from "react";
import Button from '../Button';
import Slider from "react-slick";

export default function ProductRangeManual({widget}) {
	/*layout
      title
	  products {
		title
        ratesFrom
        minLoan
        maxLtvFrom
        maxLoan
        link {
          title
          target
          url
        }
	  }
      button {
        target
        title
        url
      }*/

  const Product = ({product}) => (
	<div className="product-wrap">
		<article>
			<header>
				<h3>{product.title}</h3>
			</header>
			<div className="content">
				<table>
					<tbody>
					  {
						product.ratesFrom
						? <tr>
						    <th>Rates from</th>
						    <td>{product.ratesFrom}</td>
						  </tr>
						: null
					  }
					  {
						product.maxLtvFrom
						? <tr>
						    <th>Max LTV</th>
						    <td>{product.maxLtvFrom}</td>
						  </tr>
						: null
					  }
					  {
						product.minLoan
						? <tr>
						    <th>Min loan</th>
						    <td>{product.minLoan}</td>
						  </tr>
						: null
					  }
					  {
						product.maxLoan
						? <tr>
						    <th>Max loan</th>
						    <td>{product.maxLoan}</td>
						  </tr>
						: null
					  }
					</tbody>
				</table>
			</div>
			<footer>
			  {
				product.link
				? <Button link={product.link} className="solid purple small" />
				: null
			  }
			</footer>
		</article>
	</div>
  );

  const carouselSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
	responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
    	  arrows: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
    	  centerMode: true,
		  centerPadding: '32px',
    	  arrows: false,
        }
      }
    ]
  };


    return(
		<section className={widget.layout==='yellow' ? 'widget product-range yellow' : 'widget product-range grey'}>	
			<div className="internal">
        		<h2>{widget.title}</h2>
				<div className="products">	
				  <Slider {...carouselSettings}>
					{widget.products.length>0 && widget.products.map((product, i) => (					    
				    	<Product key={`product${i}`} product={product} />
				    ))}
				  </Slider>	
				</div>
				{
				  widget.button
				  ? <p className="buttonp"><Button link={widget.button} className="solid white" /></p>			  
				  : null
				}	
			</div>
		</section>
    )
}

