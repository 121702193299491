import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Benefits({widget}) {
	/*fieldGroupName
      intro
      title
      benefits {
        title
        copy
        image {
          localFile {
		    childImageSharp {
	           gatsbyImageData(
	             placeholder: BLURRED
	           )
	         }
          }
        }
      }*/
    return(
		<section className="widget benefits">	
        	<div className="internal">
				<div className="top">
					<h2>{widget.title}</h2>
					{
					  widget.intro
					  ? <div className="intro" dangerouslySetInnerHTML={{ __html: widget.intro }} />
					  : null
					}
				</div>
					
		        {widget.benefits?.length && (
				  <div className="benefits">
					{widget.benefits.map((benefit, i) => (
					  <div className="benefit" key={`benefit${i}`}>
						<GatsbyImage image={getImage(benefit.image.localFile)} alt={benefit.title} />
						<h3>{benefit.title}</h3>
						<div className="copy" dangerouslySetInnerHTML={{ __html: benefit.copy }} />
					  </div>
					))}
				  </div>
				)}	
			</div>
		</section>
    )
}

